/* 6.1 General styles ======================================================= */

.card-link {
    color: #3f4957;

    &:hover {
        color: #3f4957;
    }
}

section, .section {
    position: relative;

    [class^='container'] {
        padding-top: $section-padding / 2;
        padding-bottom: $section-padding / 2;
        position: relative;

        @include media-breakpoint-up($grid-float-breakpoint) {
            padding-top: $section-padding;
            padding-bottom: $section-padding;
        }
    }

    .container-wide {
        overflow-x: hidden;

        .row {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }


}

.section-heading {
    margin-bottom: $padding-default;
    position: relative;

    h2 {
        margin-top: 0;
        margin-bottom: ($padding-default / 2);

    }
 
    p {
        font-weight: $font-weight-light;
    }
}

@media screen and (max-width: 375px) {
    h2 {
        font-size: 1.5rem;
    }
}
