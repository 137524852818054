.step-form {
  width: 100%;
}

.step-form > div > label {
  width: calc(100% - 10px);
  box-shadow: 0px 3px 14px 0px rgb(0 0 0 / 20%);
  min-height: 80px;
  cursor: pointer;
  margin: 10px;
}

@media screen and (max-width: 300px) {
  .step-form > div > label {
    height: 100px;
  }
}

.step-form > div > label > div > [type=radio] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.step-form > div > label > div > [type=checkbox] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.step-form > div > label > div > [type=radio] + img {
  margin: 10px;
  max-width: 100%;
  height: 100px;
}

.step-form > div > label > div > [type=checkbox] + img {
  margin: 10px;
  max-width: 100%;
  height: 100px;
}

.step-form > label > div > div {
  text-align: left;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

//.step-form > label > div > div > div {
//  margin: 10px;
//}

.select-title {
  font-weight: bold;
  text-align: left;
  margin: 10px;
}

.select-subtitle {
  text-align: left;
  margin: 10px;
}


.select-checkbox {
  min-height: 20px;
  border: solid rgb(0 0 0 / 20%);
  min-width: 20px;
  float: right;
}


.step-form > div:last-child > button {
  margin: 10px;
}

.step-form > div:last-child {
  display: flex;
}

.label-selected {
  background-color: rgba(20, 51, 80, 0.8);
  box-shadow: none;
  margin: 10px;
  color: rgba(255, 255, 255, 0.8);
}

.checkbox-selected {
  background-image: url(../../../public/check-calc.svg);
  background-repeat: no-repeat;
}

.form-error {
  outline: 2px solid red;
  box-shadow: none;
}

.error-message {
  text-align: end;
}

.error-message > span {
  color: red;
}

.accordion-button {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}


.fa-chevron-circle-up, .fa-chevron-circle-down {
  float: right;
}

[aria-expanded="false"] {
  height: 100%;
}

[aria-expanded="false"] .fa-chevron-circle-up,
[aria-expanded="true"] .fa-chevron-circle-down {
  display: none;
  height: auto;
}

.collapse {
  padding: 10px;
}

@media screen and (max-width: 765px) {
  .responsive-collapse, .responsive-collapse div {
    width: 100%;
    //height: 100%;
  }
}

.summary-title {
  font-weight: bold;
}

.summary-item {
  margin-top: 15px;
}

.summary-subitem {
  margin-left: 10px;
}

.summary-divider {
  background-color: rgba(0, 0, 0, 0.4);
  border: 0;
  margin: 10px 0px 10px 0px;
}

.summary {

}

.summary > h5 {
  margin-left: 10px;
}
.summary-overview {
  height: 80vh;
  margin: 10px;
  padding-left: 10px;
overflow: auto;
}

.contactDialog {
  //max-width: 80vw;
  //max-height: 80vh;
}

.form-control-style {
  display: block;
    width: 100%;
    padding: 0.375rem 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin: 0;
    font-family: inherit;
}

.form-group-style {
  margin-bottom: 1rem;
  margin-right: 1rem;
}

.form-required-warning {
  color: red;
  padding-left: 10px;
}

 h5 {
  font-size: $font-size-regular;
 }

 h6 {
  font-size: $font-size-medium;
 }


