$outline-radius: 5rem;
$outline-breakpoint: 390px;
$outline-border-color: $color-accent;

.nav-outline {
    //display: inline-flex;
    justify-content: space-between;

    @include media-breakpoint-up(sm) {
        justify-content: center;
    }

    .nav-link {
        border: 1px solid $outline-border-color;
        text-align: center;
        justify-content: center;
        padding: 4px 10px;
        letter-spacing: 1px;
        margin-top: 0.5rem;
        color: $outline-border-color;

        //@include media-breakpoint-down(xs) {
        //    flex: 0 0 50%;
        //}

        @include media-breakpoint-up(sm) {
            + .nav-link {
                margin-left: -1px;
            }
        }

        @include media-breakpoint-up($grid-float-breakpoint) {
            margin-top: 0;
            padding: 4px 20px;
            font-size: 1rem;
        }

        &.active {
            background-color: $outline-border-color;
            color: $color-accent-inverse;
        }

        &:last-child {
            border-right: 1px solid $outline-border-color;
        }
    }

    &.nav-rounded {
        .nav-link {
            border-radius: $outline-radius;
            margin-right: 0.5rem;
        }

        @include media-breakpoint-up($grid-float-breakpoint) {       
            .nav-link {
                border-radius: 0;
                margin-right: 0;

                &:first-child {
                    border-radius: $outline-radius 0 0 $outline-radius;
                }

                &:last-child {
                    border-radius: 0 $outline-radius $outline-radius 0;
                }
            }
        }
    }
}
